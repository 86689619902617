import { ref } from 'vue'

let _list = ref([
    {
        type: '二维码不通过预警',
        messageType: 35,
        sealKeeperChecked: true, //印章保管员
        suPerManagerChecked: true, //超级管理员
        itemSelectedList: [], //指定人员
        isHover: false,
    },
    {
        type: '异地盖印预警',
        messageType: 3,
        sealKeeperChecked: true, //印章保管员
        suPerManagerChecked: true, //超级管理员
        itemSelectedList: [], //指定人员
        isHover: false,
    },
    {
        type: '印章异常预警',
        messageType: 4,
        sealKeeperChecked: true, //印章保管员
        suPerManagerChecked: true, //超级管理员
        itemSelectedList: [], //指定人员
        isHover: false,
    },
    {
        type: '空白页盖章预警',
        messageType: 5,
        sealKeeperChecked: true, //印章保管员
        suPerManagerChecked: true, //超级管理员
        itemSelectedList: [], //指定人员
        isHover: false,
    },
    {
        type: '低电量预警',
        messageType: 6,
        sealKeeperChecked: true, //印章保管员
        suPerManagerChecked: true, //超级管理员
        itemSelectedList: [], //指定人员
        isHover: false,
    },
    {
        type: '超出围栏预警',
        messageType: 9,
        sealKeeperChecked: true, //印章保管员
        suPerManagerChecked: true, //超级管理员
        itemSelectedList: [], //指定人员
        isHover: false,
    },
    {
        type: '超时按压预警',
        messageType: 11,
        sealKeeperChecked: true, //印章保管员
        suPerManagerChecked: true, //超级管理员
        itemSelectedList: [], //指定人员
        isHover: false,
    },
    {
        type: '用印未补拍预警',
        messageType: 19,
        sealKeeperChecked: true, //印章保管员
        suPerManagerChecked: true, //超级管理员
        itemSelectedList: [], //指定人员
        isHover: false,
    },
    {
        type: '已超时用印预警',
        messageType: 21,
        sealKeeperChecked: true, //印章保管员
        suPerManagerChecked: true, //超级管理员
        itemSelectedList: [], //指定人员
        isHover: false,
    },
    {
        type: '即将超时用印预警',
        messageType: 20,
        applicantChecked: true,
        timeInputValue: 24,
    },
    // 系统通知
    {
        type: '审批通过提醒',
        messageType: 7,
        sealKeeperChecked: true, //印章保管员
        suPerManagerChecked: true, //超级管理员
        itemSelectedList: [], //指定人员
        isHover: false,
    },
    {
        type: '完成用印提醒',
        messageType: 16,
        applicantChecked: true,
        sealKeeperChecked: true, //印章保管员
        suPerManagerChecked: true, //超级管理员
        itemSelectedList: [], //指定人员
        isHover: false,
    }
]);

// 将通知类型的共有属性添加进去
const setList = () => {
    // 添加属性
    _list.value = _list.value.map(item => ({
        ...item,
        id: '',
        typeChecked: true, //消息开关
        disabled: false,
        cleckMobile: true, // 移动端
        cleckPc: true, // pc
        cleckMessage: true, // 短信
        cleckStamp: true // 盖印人
    }));
    return _list;
}

export default setList()



