<!--
 * @Author: chenxu chenxu@libawall.cn
 * @Date: 2023-11-29 11:25:27
 * @LastEditors: cyx 
 * @LastEditTime: 2024-02-23 12:15:29
 * @FilePath: \yda_web_manage\src\views\businessManage\message\index.vue
 * @Description: 消息管理中心
-->
<template>
  <section class="seal-tabs">
    <a-tabs v-model:activeKey="activeKey" class="message" @change="changeActive">
      <a-tab-pane key="1" tab="预警通知" force-render></a-tab-pane>
      <a-tab-pane key="2" tab="系统通知"></a-tab-pane>
    </a-tabs>
    <div class="message-manage">
      <!-- <p class="title">预警通知</p> -->
      <div
        v-for="(item, index) in infoList"
        :key="index"
        :class="['list-box', currentChangeIndex === index ? 'bg-red' : 'bg-grey']"
      >
        <div class="top">
          <div class="top-left">
            <span class="type">
              {{ item.type }}
              <span class="type-tips" v-if="item.messageType === 7">（配置后盖印人在对应端可查看盖印码信息）</span>
            </span>
            <a-switch
              v-if="item.messageType !== 7"
              v-model:checked="item.typeChecked"
              @change="changeStatus(item, index, true, $event)"
            />
          </div>
          <a-button
            class="top-right"
            @click="saveChange(item)"
            :type="currentChangeIndex === index ? 'primary' : 'default'"
          >
            <i class="iconfont icon-baocun save-icon"></i>保存
          </a-button>
        </div>
        <div v-if="item.type !== '即将超时用印预警' && item.messageType !== 7" class="row-info">
          <span class="desc">消息接收人：</span>
          <div
            @mouseover="mouseOver(item)"
            @mouseleave="mouseLeave(item)"
            @click.stop="openSelector(item, index)"
            :class="['printer', { 'select-disabled': item.disabled }]"
            :style="{
              padding: item.itemSelectedList.length ? '5px 21px 0 12px' : '5px 12px',
              border: item.isHover ? '1px solid #0A7BFF' : '1px solid #D9D9D9'
            }"
          >
            <template v-if="item.itemSelectedList.length">
              <div v-for="(childItem, childIndex) in item.itemSelectedList" :key="childIndex" class="pt-tag">
                <span class="pt-name" :title="childItem.dataName">
                  {{ childItem.dataName }}
                  <span v-if="childItem.dataMembers !== undefined">({{ childItem.dataMembers }}人)</span>
                </span>
                <CloseOutlined @click.stop="deleteStaff(item, index, childIndex)" class="pt-close" />
              </div>
            </template>
            <span v-else class="default-placeholder">请选择预警接收人员</span>
            <DownOutlined class="down-arrow" />
          </div>
          <a-checkbox
            v-model:checked="item.sealKeeperChecked"
            class="checkbox"
            @change="changeStatus(item, index)"
            :disabled="item.disabled"
            >印章保管员</a-checkbox
          >
          <a-checkbox
            v-model:checked="item.suPerManagerChecked"
            class="checkbox"
            @change="changeStatus(item, index)"
            :disabled="item.disabled"
            >超级管理员</a-checkbox
          >
          <a-checkbox
            v-if="item.messageType === 16"
            v-model:checked="item.cleckStamp"
            class="checkbox"
            @change="changeStatus(item, index)"
            :disabled="item.disabled"
            >盖印人</a-checkbox
          >
          <a-checkbox
            v-if="item.messageType === 16"
            v-model:checked="item.applicantChecked"
            class="checkbox"
            @change="changeStatus(item, index)"
            :disabled="item.disabled"
            >申请人</a-checkbox
          >
        </div>
        <div v-else-if="item.messageType === 20" class="column-info">
          <div class="desc">
            消息接收人：
            <a-checkbox v-model:checked="item.applicantChecked" disabled>申请人</a-checkbox>
          </div>
          <div class="desc">
            消息提醒时间：用印超时前
            <a-input-number
              :style="{ width: '71px', margin: '0 8px' }"
              :disabled="item.disabled"
              v-model:value="item.timeInputValue"
              :precision="0"
              :min="1"
              :max="48"
              :parser="
                value =>
                  value
                    .replace(/[^\d.]/g, '')
                    .replace(/\.{2,}/g, '.')
                    .replace('.', '$#$')
                    .replace('$#$', '.')
                    .replace(/^(\-)*(\d+)\.(\d).*$/, '$1$2.$3')
                    .replace(/^\./g, '')
                    .replace(/(\.(?!0|5).)?$/g, '')
              "
              @focus="changeStatus(item, index)"
            />小时对申请人发送预警
          </div>
        </div>
        <div class="configuration">
          <div>推送端配置：</div>
          <div>
            <a-checkbox v-model:checked="item.cleckMobile" class="checkbox" @change="configuration(item, index)"
              >移动端</a-checkbox
            >
            <a-checkbox v-model:checked="item.cleckPc" class="checkbox" @change="configuration(item, index)"
              >PC端</a-checkbox
            >
            <a-checkbox v-model:checked="item.cleckMessage" class="checkbox" @change="configuration(item, index)"
              >短信</a-checkbox
            >
          </div>
        </div>
      </div>
      <a-modal
        centered
        title="选择预警接收人"
        width="655px"
        class="global-range-modal"
        :visible="visible"
        @cancel="handleCancel"
        @ok="handleOk"
      >
        <GlobalSelector
          :visible="visible"
          :selectedList="selectedList"
          :selectorInfo="selectorInfo"
          :renderList="renderList"
          @getSearchInfo="getSearchInfo"
          @toNext="toNext"
          @changeData="changeData"
          ref="globalSelector"
        ></GlobalSelector>
      </a-modal>
    </div>
  </section>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, createVNode } from 'vue'
import { DownOutlined, CloseOutlined, ExclamationOutlined } from '@ant-design/icons-vue'
import GlobalSelector from '@/components/GlobalSelector'
import { getDepartmentOrStaff, transformPeople, getMessage, changeMessage } from '@/apis/businessManage'
import { formateListData, transformData } from '@/utils/selectorListFormate.js'
import { cmsNotice } from '@/utils/utils'
import { Modal } from 'ant-design-vue'
import noticeList from './noticeList'

export default defineComponent({
  components: {
    DownOutlined,
    CloseOutlined,
    GlobalSelector
  },
  props: {},
  setup() {
    const designeeVOList = ref([])
    const selectedList = ref([])
    const globalSelector = ref(null)
    //当前操作项
    const currentChangeIndex = ref(-1)
    const selectorInfo = reactive({
      placeholder: '请输入员工姓名或部门',
      abelCheckAll: true,
      ableSelectDep: true,
      ableChangeData: true
    })
    const visible = ref(false)
    const renderList = ref([])
    const activeKey = ref('1')
    const infoList = ref([]) // 展示用的
    const selectorType = ref(1)
    //删除已选择的人员
    const deleteStaff = (item, index, childIndex) => {
      if (item.disabled) return
      currentChangeIndex.value = index
      infoList.value[currentChangeIndex.value].itemSelectedList.splice(childIndex, 1)
    }
    //修改状态
    const changeStatus = (item, index, type, e) => {
      currentChangeIndex.value = index
      if (type) {
        //预警类型开关
        item.disabled = !e
      }
    }
    //弹窗取消事件
    const handleCancel = () => {
      visible.value = false
      globalSelector.value.cancelEvent()
    }
    //弹窗确定事件
    const handleOk = () => {
      let arr = [...globalSelector.value.okEvent()]
      transformData(arr).then(res => {
        console.log('去重的人员', res)
        infoList.value[currentChangeIndex.value].itemSelectedList = res
      })

      handleCancel()
    }
    // 处理部门转化人员
    // const transformData = async arr => {
    //   console.log('arr的值', arr)
    //   let deptIds = []
    //   let staffList = []
    //   let depToStaff = []
    //   arr.forEach(ele => {
    //     if (ele.dataType === 2) {
    //       //部门
    //       deptIds.push(ele.dataId)
    //     } else {
    //       staffList.push(ele)
    //     }
    //   })

    //   await transformPeople({ deptIds })
    //     .then(res => {
    //       console.log('部门转人员', res)
    //       if (res.success && res.data?.length) {
    //         depToStaff = res.data.map(ele => {
    //           ele.dataName = ele.staffName
    //           ele.dataId = ele.staffId
    //           ele.dataType = 1
    //           ele.icon = ele.avatarId ? ele.fullAvatar : require('@/assets/images/default-avatar.png')
    //           return ele
    //         })
    //       }
    //     })
    //     .catch(err => {
    //       console.log(err)
    //     })
    //   staffList = [...staffList, ...depToStaff]
    //   console.log('去重前人员数组', staffList)
    //   for (var i = 0; i < staffList.length - 1; i++) {
    //     for (var j = i + 1; j < staffList.length; j++) {
    //       if (staffList[i].dataId == staffList[j].dataId) {
    //         staffList.splice(j, 1)
    //         j--
    //       }
    //     }
    //   }
    //   console.log('去重后的人员数组', staffList)
    //   infoList.value[currentChangeIndex.value].itemSelectedList = staffList
    // }
    //保存
    const saveChange = item => {
      console.log('当前点击保存项', item, designeeVOList.value)
      designeeVOList.value = []
      let receiverType = []
      let messageTerminalType = []
      if (item.sealKeeperChecked) receiverType.push(1)
      if (item.suPerManagerChecked) receiverType.push(2)
      if (item.itemSelectedList?.length) receiverType.push(3)
      if (item.applicantChecked) receiverType.push(4)
      if (item.itemSelectedList?.length)
        item.itemSelectedList.forEach(ele => {
          designeeVOList.value.push({ staffId: ele.dataId })
        })
      if (item.cleckMobile) messageTerminalType.push(1)
      if (item.cleckPc) messageTerminalType.push(2)
      if (item.cleckMessage) messageTerminalType.push(3)
      if (item.cleckStamp) receiverType.push(5)
      if (item.messageType === 7) {
        receiverType = []
      }
      if (!item.cleckMobile && !item.cleckPc && !item.cleckMessage) {
        // 二次弹窗
        Modal.confirm({
          title: `提示`,
          content: '请必须选择一个推送端配置！',
          centered: true,
          width: '490px',
          icon: createVNode(ExclamationOutlined),
          onOk: async () => {}
        })
        return
      }
      let data = {
        id: item.id,
        messageType: item.messageType,
        messageSwitch: item.typeChecked ? 1 : 2,
        receiverType: receiverType,
        designeeVOList: designeeVOList.value,
        ptimeBeforeWarn: item.timeInputValue,
        messageTerminalType: messageTerminalType
      }
      changeMessage(data)
        .then(res => {
          if (res.success) cmsNotice('success', '变更已保存')
        })
        .catch(err => {
          console.log(err)
        })
    }
    //TODO :选择预警接收人
    const openSelector = (item, index) => {
      !item.disabled && (visible.value = true)
      currentChangeIndex.value = index
      console.log('item.itemSelectedList的值', item.itemSelectedList)
      selectedList.value = item.itemSelectedList
    }
    //弹窗数据
    const getScopeList = async data => {
      let params = {
        selectorType: selectorType.value //1部门人员2部门3员工
      }
      const res = await getDepartmentOrStaff({ ...params, ...data })
      if (res.success) {
        console.log('全部数据', res)
        handelData(res)
      }
    }
    // 处理左侧数据
    const handelData = res => {
      renderList.value = formateListData(res)
      // console.log('处理后的renderList的值', renderList.value)
    }
    //搜索
    const getSearchInfo = async e => {
      let data = {
        searchName: e
      }
      getScopeList(data)
    }
    //前往下级
    const toNext = id => {
      let data = {
        departmentId: id
      }
      getScopeList(data)
    }
    //获取消息管理中心信息
    const getInfo = () => {
      console.log('_arr', noticeList)
      getMessage()
        .then(res => {
          console.log('消息管理中心信息', res)
          noticeList.value.forEach(item => {
            res.data.forEach(ele => {
              // console.log('90329784628----', ele)
              if (item.messageType === ele.messageType) {
                item.id = ele.id
                item.typeChecked = ele.messageSwitch === 1 //1、开启 2、关闭
                item.disabled = !item.typeChecked
                if (item.sealKeeperChecked) item.sealKeeperChecked = ele.receiverType.some(it => it === 1) //印章保管员
                if (item.suPerManagerChecked) item.suPerManagerChecked = ele.receiverType.some(it => it === 2) //超级管理员
                if (item.itemSelectedList)
                  item.itemSelectedList = ele.designeeVOList.map(it => {
                    // 指定人员
                    it.dataName = it.staffName
                    it.dataId = it.staffId
                    it.dataType = 1
                    it.icon = it.avatarId ? it.fullAvatar : require('@/assets/images/default-avatar.png')
                    return it
                  })
                if (item.timeInputValue) item.timeInputValue = ele.ptimeBeforeWarn ?? 24 //用印超时前提示时间
                if (ele.messageTerminalType !== null) {
                  item.cleckMobile = ele.messageTerminalType.some(it => it === 1)
                  item.cleckPc = ele.messageTerminalType.some(it => it === 2)
                  item.cleckMessage = ele.messageTerminalType.some(it => it === 3)
                }
                if (item.cleckStamp) item.cleckStamp = ele.receiverType.some(it => it === 5)
                if (item.applicantChecked) item.applicantChecked = ele.receiverType.some(it => it === 4)
              }
            })
          })
          changeActive()
        })
        .catch(err => {
          console.log(err)
        })
    }
    const mouseOver = item => {
      !item.disabled && (item.isHover = true)
    }
    const mouseLeave = item => {
      item.isHover = false
    }

    const configuration = (item, index) => {
      console.log('看看1快康康', item)
      // console.log(infoList.value)
      currentChangeIndex.value = index
    }

    // 切换tab
    const changeActive = () => {
      console.log(infoList.value)
      let meaasgeList = [] // 系统通知
      let warningList = [] // 预警通知
      noticeList.value.forEach(item => {
        if ([7, 16].includes(item.messageType)) {
          meaasgeList.push(item)
        } else {
          warningList.push(item)
        }
        if (activeKey.value === '1') {
          infoList.value = warningList
        } else {
          infoList.value = meaasgeList
        }
      })
    }
    // 切换数据
    const changeData = ({ isOrganizationData, searchValue }) => {
      console.log('切换的数据的类型', isOrganizationData)
      if (isOrganizationData) {
        // 组织架构数据
        selectorType.value = 1
      } else {
        //人员数据
        selectorType.value = 3
      }
      getSearchInfo(searchValue)
    }

    onMounted(() => {
      getScopeList()
      getInfo()
    })
    return {
      noticeList,
      currentChangeIndex,
      deleteStaff,
      changeStatus,
      selectorInfo,
      visible,
      handleCancel,
      handleOk,
      saveChange,
      openSelector,
      getScopeList,
      renderList,
      getSearchInfo,
      toNext,
      globalSelector,
      selectedList,
      getInfo,
      // transformData,
      designeeVOList,
      mouseOver,
      mouseLeave,
      activeKey,
      changeActive,
      infoList,
      configuration,
      changeData,
      selectorType
    }
  }
})
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>
